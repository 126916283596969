import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const TOKEN = 'e84c6ff7fdfa'

const getToken = () => {  
    let token = localStorage.getItem(TOKEN)
    return token
};

export default new Vuex.Store({
  state: {
      isLogin: false,
      paginaAtual: '',
      loading: false,
      estadoMenu: ''
  },

  mutations: {
    loginUsuario(state, value){
        localStorage.setItem(TOKEN, value)
        state.isLogin = true
        state.token = value
    },

    setLoading(state, value){
        state.loading = value
    },
   
    setMenu(state, value){
        state.estadoMenu = value
    },

    rotaAtual(state, value){
        state.paginaAtual = value
    },

    removerToken(state){
        localStorage.removeItem(TOKEN)
        state.isLogin = stop
        state.token = null
    },

    obterToken(state){
        let token = getToken()
        if (token){
            state.isLogin = true
            state.token = token
        }else{
            state.isLogin = false
            state.token = null
        }
    }
  },

  getters:{
    isLoading(state){
        return state.loading
    },

    isMenu(state){
        return state.estadoMenu
    }
  },

  actions: {
 
  },
  
})
