<template>
        <footer class="footer">
            <div
                class="
                    d-sm-flex
                    justify-content-center justify-content-sm-between
                "
            >
                <span
                    class="
                        text-muted text-center text-sm-left
                        d-block d-sm-inline-block
                    "
                    >Sistema de Gerenciamento de Pedidos</span
                >
                <span
                    class="
                        float-none float-sm-right
                        d-block
                        mt-1 mt-sm-0
                        text-center
                    "
                    >Dashboard Bia Truffas e Doces.</span
                >
            </div>
        </footer>
        <!-- partial -->
</template>

<script>
export default {
    name: 'Footer',

};
</script>

<style>
</style>