var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"sidebar sidebar-offcanvas",class:_vm.isMenu,attrs:{"id":"sidebar"}},[_c('ul',{staticClass:"nav"},[_c('li',{staticClass:"nav-item",class:{
                active: this.$store.state.paginaAtual == 'Dashboard',
            }},[_c('router-link',{staticClass:"nav-link",attrs:{"active-class":"active","to":{ name: 'Dashboard' },"exact":""}},[_c('i',{staticClass:"mdi mdi-grid-large menu-icon"}),_c('span',{staticClass:"menu-title"},[_vm._v("Dashboard")])])],1),_c('li',{staticClass:"nav-item",class:{
                active: this.$store.state.paginaAtual == 'Categorias',
            }},[_c('router-link',{staticClass:"nav-link",attrs:{"active-class":"active","to":{ name: 'Categorias' },"exact":""}},[_c('i',{staticClass:"mdi mdi-apps menu-icon"}),_c('span',{staticClass:"menu-title"},[_vm._v("Categorias")])])],1),_c('li',{staticClass:"nav-item",class:{ active: this.$store.state.paginaAtual == 'Produtos' }},[_c('router-link',{staticClass:"nav-link",attrs:{"active-class":"active","to":{ name: 'Produtos' },"exact":""}},[_c('i',{staticClass:"mdi mdi-view-agenda menu-icon"}),_c('span',{staticClass:"menu-title"},[_vm._v("Produtos")])])],1),_c('li',{staticClass:"nav-item",class:{
                active:
                    this.$store.state.paginaAtual == 'ListaPedidos' ||
                    this.$store.state.paginaAtual == 'DetalhesPedidos',
            }},[_c('router-link',{staticClass:"nav-link",attrs:{"active-class":"active","to":{ name: 'ListaPedidos' },"exact":""}},[_c('i',{staticClass:"mdi mdi-cart menu-icon"}),_c('span',{staticClass:"menu-title"},[_vm._v("Pedidos")])])],1),_c('li',{staticClass:"nav-item",class:{ active: this.$store.state.paginaAtual == 'Frete' }},[_c('router-link',{staticClass:"nav-link",attrs:{"active-class":"active","to":{ name: 'Frete' },"exact":""}},[_c('i',{staticClass:"mdi mdi-truck menu-icon"}),_c('span',{staticClass:"menu-title"},[_vm._v("Calcular Frete")])])],1),_c('li',{staticClass:"nav-item",class:{
                active: this.$store.state.paginaAtual == 'Configuracoes',
            }},[_c('router-link',{staticClass:"nav-link",attrs:{"active-class":"active","to":{ name: 'Configuracoes' },"exact":""}},[_c('i',{staticClass:"mdi mdi-settings menu-icon"}),_c('span',{staticClass:"menu-title"},[_vm._v("Configurações")])])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"active-class":"active","to":{ name: 'logout' },"exact":""}},[_c('i',{staticClass:"mdi mdi-logout menu-icon"}),_c('span',{staticClass:"menu-title"},[_vm._v("Sair")])])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }