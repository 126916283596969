<template>
    <nav class="sidebar sidebar-offcanvas" :class="isMenu" id="sidebar">
        <ul class="nav">
            <li
                class="nav-item"
                :class="{
                    active: this.$store.state.paginaAtual == 'Dashboard',
                }"
            >
                <router-link
                    active-class="active"
                    :to="{ name: 'Dashboard' }"
                    exact
                    class="nav-link"
                >
                    <i class="mdi mdi-grid-large menu-icon"></i>
                    <span class="menu-title">Dashboard</span>
                </router-link>
            </li>
            <li
                class="nav-item"
                :class="{
                    active: this.$store.state.paginaAtual == 'Categorias',
                }"
            >
                <router-link
                    active-class="active"
                    :to="{ name: 'Categorias' }"
                    exact
                    class="nav-link"
                >
                    <i class="mdi mdi-apps menu-icon"></i>
                    <span class="menu-title">Categorias</span>
                </router-link>
            </li>
            <li
                class="nav-item"
                :class="{ active: this.$store.state.paginaAtual == 'Produtos' }"
            >
                <router-link
                    active-class="active"
                    :to="{ name: 'Produtos' }"
                    exact
                    class="nav-link"
                >
                    <i class="mdi mdi-view-agenda menu-icon"></i>
                    <span class="menu-title">Produtos</span>
                </router-link>
            </li>
            <li
                class="nav-item"
                :class="{
                    active:
                        this.$store.state.paginaAtual == 'ListaPedidos' ||
                        this.$store.state.paginaAtual == 'DetalhesPedidos',
                }"
            >
                <router-link
                    active-class="active"
                    :to="{ name: 'ListaPedidos' }"
                    exact
                    class="nav-link"
                >
                    <i class="mdi mdi-cart menu-icon"></i>
                    <span class="menu-title">Pedidos</span>
                </router-link>
            </li>
            <li
                class="nav-item"
                :class="{ active: this.$store.state.paginaAtual == 'Frete' }"
            >
                <router-link
                    active-class="active"
                    :to="{ name: 'Frete' }"
                    exact
                    class="nav-link"
                >
                    <i class="mdi mdi-truck menu-icon"></i>
                    <span class="menu-title">Calcular Frete</span>
                </router-link>
            </li>
            <li
                class="nav-item"
                :class="{
                    active: this.$store.state.paginaAtual == 'Configuracoes',
                }"
            >
                <router-link
                    active-class="active"
                    :to="{ name: 'Configuracoes' }"
                    exact
                    class="nav-link"
                >
                    <i class="mdi mdi-settings menu-icon"></i>
                    <span class="menu-title">Configurações</span>
                </router-link>
            </li>
            <li class="nav-item">
                <router-link
                    active-class="active"
                    :to="{ name: 'logout' }"
                    exact
                    class="nav-link"
                >
                    <i class="mdi mdi-logout menu-icon"></i>
                    <span class="menu-title">Sair</span>
                </router-link>
            </li>
        </ul>
    </nav>
</template>

<script>
import store from "@/store";
import { mapGetters } from "vuex";
export default {
    computed: {
        ...mapGetters(["isMenu"]),
    },
    methods: {
        fecharMenu() {
            var descendentes = document.querySelectorAll("#sidebar a");
            for (var i = 0; i < descendentes.length; i++) {
                descendentes[i].addEventListener("click", function () {
                    var largura =
                        window.innerWidth ||
                        document.documentElement.clientWidth ||
                        document.body.clientWidth;
                    if (largura <= 991) {
                        store.commit("setMenu", "");
                    }
                });
            }
        },
    },
    mounted() {
        this.fecharMenu();
    },
};
</script>

<style>
</style>