<template>
<div class="main-panel">
    <div class="content-wrapper">
        <div class="row">
            <div class="col-sm-12">
                <div class="home-tab">
                    <div class="tab-content tab-content-basic">
                        <div class="tab-pane fade show active" id="overview" role="tabpanel" aria-labelledby="overview">
                            <div class="row">

                                <div class="col-sm-6">
                                    <div class="col-md-12 col-lg-12 grid-margin stretch-card">
                                        <div class="card bg-primary card-rounded">
                                        <div class="card-body pb-0">
                                            <h4 class="card-title card-title-dash text-white mb-4">Total Pedidos</h4>
                                            <div class="row">
                                            <div class="col-sm-12 mb-3">
                                                <h2 class="text-info">{{analytics.totalPedidos}}</h2>
                                            </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>


                                <div class="col-sm-6">
                                    <div class="col-md-12 col-lg-12 grid-margin stretch-card">
                                        <div class="card bg-primary card-rounded">
                                        <div class="card-body pb-0">
                                            <h4 class="card-title card-title-dash text-white mb-4">Pedidos Pendentes Pagamentos</h4>
                                            <div class="row">
                                            <div class="col-sm-12 mb-3">
                                                <h2 class="text-info">{{analytics.pedidosPendentesPagamentos}}</h2>
                                            </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>


                                <div class="col-sm-6">
                                    <div class="col-md-12 col-lg-12 grid-margin stretch-card">
                                        <div class="card bg-primary card-rounded">
                                        <div class="card-body pb-0">
                                            <h4 class="card-title card-title-dash text-white mb-4">Pedidos Pendentes</h4>
                                            <div class="row">
                                            <div class="col-sm-12 mb-3">
                                                <h2 class="text-info">{{analytics.pedidosPendentes}}</h2>
                                            </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>


                                <div class="col-sm-6">
                                    <div class="col-md-12 col-lg-12 grid-margin stretch-card">
                                        <div class="card bg-primary card-rounded">
                                        <div class="card-body pb-0">
                                            <h4 class="card-title card-title-dash text-white mb-4">Pedidos Entregues</h4>
                                            <div class="row">
                                            <div class="col-sm-12 mb-3">
                                                <h2 class="text-info">{{analytics.pedidosEntregues}}</h2>
                                            </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                            <div v-if="ultimosPedidos.length > 0" class="row">
                                <div class="col-lg-12 d-flex flex-column">
                                    <div class="row flex-grow">
                                        <div class="col-12 grid-margin stretch-card">
                                            <div class="card card-rounded">
                                                <div class="card-body">
                                                    <div class="d-sm-flex justify-content-between align-items-start">
                                                        <div>
                                                            <h4 class="card-title card-title-dash">
                                                                Últimos Pedidos
                                                            </h4>
                                                            <p class="card-subtitle card-subtitle-dash">
                                                                Listagem dos últimos 5 pedidos
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div class="table-responsive mt-1">
                                                        <table class="table select-table">
                                                            <thead>
                                                                <tr>
                                                                    <th>
                                                                        Cliente
                                                                    </th>
                                                                    <th>
                                                                        Pedido
                                                                    </th>
                                                                    <th>
                                                                        Status Pagamento
                                                                    </th>
                                                                    <th>
                                                                        Status Pedido
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr v-for="item in ultimosPedidos" :key="item.id">
                                                                    <td>
                                                                        <div class="d-flex">
                                                                            <img src="https://bia-truffas.s3.us-west-004.backblazeb2.com/customer.png" alt="" />
                                                                            <div>
                                                                                <h6>
                                                                                    {{item.cliente.nome}}
                                                                                </h6>
                                                                                <p>
                                                                                    {{item.cliente.celular}}<br>
                                                                                    {{item.cliente.email}}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <h6>
                                                                            Pedido {{item.codigo}}
                                                                        </h6>
                                                                        <p>
                                                                            Valor Total: {{item.valor.valor_total_pedido_formatado}}
                                                                        </p>
                                                                       <div class="mt-2">

                                                                        <router-link
                                                                            :to="{ name: 'DetalhesPedidos', params : {id : item.id} }"
                            
                                                                        >
                                                                            Visualizar</router-link>

                                                                       </div>
                                                                    </td>
                                                                    <td>
                                                                        <div>
                                                                        <div class="badge" :class="corStatus('pagamento', item.status.id_status_pagamento)">
                                                                            {{item.status.pagamento}}
                                                                        </div>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div class="badge" :class="corStatus('pedido', item.status.id_status_pedido)">
                                                                            {{item.status.pedido}}
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- content-wrapper ends -->
    <!-- partial:partials/_footer.html -->
</div>

</template>

<script>
import axiosApi from '@/axios'
import store from '@/store'
export default {
    name: "Home",

    data(){
        return{
            ultimosPedidos: [],
            analytics:{
                totalPedidos: 0,
                pedidosPendentesPagamentos: 0,
                pedidosPendentes: 0,
                pedidosEntregues: 0
            }
        }
    },

    methods:{
        corStatus(tipo, id){
            let valor = ''
            if (tipo == 'pagamento'){
                switch (id){
                    case 1:
                        valor = 'badge-opacity-warning'
                        break
                    case 2:
                        valor = 'badge-opacity-success'
                        break
                    case 3:
                        valor = 'badge-opacity-danger'
                        break
                }
            }else{
                if (tipo == 'pedido'){
                    switch (id){
                        case 1:
                            valor = 'badge-opacity-warning'
                            break
                        case 2:
                            valor = 'badge-opacity-warning'
                            break
                        case 3:
                            valor = 'badge-opacity-warning'
                            break
                        case 4:
                            valor = 'badge-opacity-warning'
                            break
                        case 5:
                            valor = 'badge-opacity-success'
                            break
                        case 6:
                            valor = 'badge-opacity-danger'
                            break
                    }
                }
            }

            return valor
        },

        async listarUltimosPedidos(){
            const response = await axiosApi.get('/admin/pedido?ordem=desc&limite=5')
            return response.data.pedidos
        },

        async totalAnalytics(){
            let response = null

            response = await axiosApi.get('/admin/analytics/total-pedidos')
            this.analytics.totalPedidos = response.data.total

            response = await axiosApi.get('/admin/analytics/pedidos-pendentes-pagamentos')
            this.analytics.pedidosPendentesPagamentos = response.data.total

            response = await axiosApi.get('/admin/analytics/pedidos-pendentes')
            this.analytics.pedidosPendentes = response.data.total

            response = await axiosApi.get('/admin/analytics/pedidos-entregues')
            this.analytics.pedidosEntregues = response.data.total
        }

    },

    async mounted(){
        store.commit('setLoading', true)
        this.ultimosPedidos = await this.listarUltimosPedidos()
        await this.totalAnalytics()
        store.commit('setLoading', false)
    }
};
</script>

<style>
</style>
