import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from '../views/Dashboard.vue'
import ToBar from '../layout/ToBar.vue'
import Menu from '../layout/Menu.vue'
import Footer from '../layout/Footer.vue'
import store from '@/store'

Vue.use(VueRouter)

const DEFAULT_TITLE = 'DashBoard Bia Truffas e Doces'

const routes = [{
    path: '/',
    name: 'Dashboard',
    components: {
      default: Dashboard,
      tobar: ToBar,
      menu: Menu,
      footer: Footer
    },
    meta:{
        title: 'Home',
        requireLogin: true
    }
  },
  {
    path: '/categorias',
    name: 'Categorias',
    components: {
      default: () => import('../views/Categorias.vue'),
      tobar: ToBar,
      menu: Menu,
      footer: Footer
    },
    meta:{
        title: 'Categorias',
        requireLogin: true
    }
  },
  {
    path: '/configuracoes',
    name: 'Configuracoes',
    components: {
      default: () => import('../views/Configuracoes.vue'),
      tobar: ToBar,
      menu: Menu,
      footer: Footer
    },
    meta:{
        title: 'Configurações',
        requireLogin: true
    }
  },

  {
    path: '/calcular-frete',
    name: 'Frete',
    components: {
      default: () => import('../views/Frete.vue'),
      tobar: ToBar,
      menu: Menu,
      footer: Footer
    },
    meta:{
        title: 'Calcular Frete',
        requireLogin: true
    }
  },

  {
    path: '/produtos',
    name: 'Produtos',
    components: {
      default: () => import('../views/Produtos.vue'),
      tobar: ToBar,
      menu: Menu,
      footer: Footer
    },
    meta:{
        title: 'Produtos',
        requireLogin: true
    }
  },
  {
    path: '/pedidos',
    name: 'Pedidos',
    components: {
      default: () => import('../views/Pedidos.vue'),
      tobar: ToBar,
      menu: Menu,
      footer: Footer
    },

    children: [{
        path: '/pedidos',
        name: 'ListaPedidos',
        component: () => import('../layout/ListaPedidos.vue'),
        meta:{
            title: 'Pedidos',
            requireLogin: true
        }
      },
      {
        path: ':id',
        props: true,
        name: 'DetalhesPedidos',
        component: () => import('../layout/DetalhesPedidos.vue'),
        meta:{
            title: 'Detalhe Pedido',
            requireLogin: true
        }
      },
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue'),
    meta:{
        title: 'Login',
        requireLogin: false
    }
  },

  {
    path: '/sair',
    name: 'logout',
    component: {
        beforeRouteEnter(to, from, next) {
            store.commit('removerToken')
            next('/login')
        }
    }
  },

  {
    path: '*',
    name: 'NotFound',
    components: {
      default: () => import('../views/NotFound.vue'),
      tobar: ToBar,
      menu: Menu,
      footer: Footer
    },
    meta:{
        title: 'Página Não Encontrada',
        requireLogin: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth',
      }
    }
    if (savedPosition) {
      return savedPosition
    } else {
      return {
        x: 0,
        y: 0
      }
    }
  }
})

router.afterEach((to) => {
    Vue.nextTick(() => {
        document.title = to.meta.title  + ' | ' + DEFAULT_TITLE || DEFAULT_TITLE;
    })
})

router.beforeEach((to, from, next) => {
    store.commit('rotaAtual', to.name)
    if (to.meta.requireLogin) {
        store.commit('obterToken')
        if (!store.state.isLogin) {
            next('/login')
        } else {
            next()
        }
    }else{
        next()
    }
})

export default router