<template>
    <nav
        class="
            navbar
            default-layout
            col-lg-12 col-12
            p-0
            fixed-top
            d-flex
            align-items-top
            flex-row
        "
    >
        <div
            class="
                text-center
                navbar-brand-wrapper
                d-flex
                align-items-center
                justify-content-start
            "
        >
            <div class="me-3">
                <button
                    class="navbar-toggler navbar-toggler align-self-center"
                    type="button"
                    @click.prevent="minimize()"
                >
                    <span class="icon-menu"></span>
                </button>
            </div>
            <div>
                <a class="navbar-brand brand-logo" href="">
                    <img
                        src="@/assets/images/logo.png"
                        style="height: 60px"
                        alt="logo"
                    />
                </a>
                <a class="navbar-brand brand-logo-mini" href="">
                    <img src="@/assets/images/logo-icone.png" alt="logo" />
                </a>
            </div>
        </div>
        <div class="navbar-menu-wrapper d-flex align-items-top">
            <ul class="navbar-nav">
                <li
                    class="nav-item font-weight-semibold d-none d-lg-block ms-0"
                >
                    <h1 class="welcome-text">
                        Dashboard
                        <span class="text-black fw-bold">Bia Truffas e Doces</span>
                    </h1>
                </li>
            </ul>

            <button
                class="
                    navbar-toggler navbar-toggler-right
                    d-lg-none
                    align-self-center
                "
                type="button"
                @click.prevent="minimizeMobile"
            >
                <span class="mdi mdi-menu"></span>
            </button>
        </div>

        <loading
            :active.sync="isLoading"
            :can-cancel="false"
            :is-full-page="true"
        ></loading>
    </nav>
</template>

<script>
import store from "@/store";
import { mapGetters } from "vuex";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
    components: {
        Loading,
    },

    computed: {
        ...mapGetters(["isLoading"]),
    },
    methods: {
        minimize() {
            if (document.body.classList.contains("sidebar-icon-only")) {
                document.body.classList.remove("sidebar-icon-only");
            } else {
                document.body.classList.add("sidebar-icon-only");
            }
        },
        minimizeMobile() {
            if (store.state.estadoMenu == "active") {
                store.commit("setMenu", "");
            } else {
                store.commit("setMenu", "active");
            }
        },
    },
};
</script>

<style>
@media (max-width: 991px) {
    .navbar .navbar-menu-wrapper .navbar-toggler.navbar-toggler-right {
      position: absolute;
      right: 15px;
    }
}
</style>