import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import router from '@/router'
import store from '@/store'

Vue.use(VueAxios, axios)

const TOKEN = 'e84c6ff7fdfa'

const axiosApi = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL
})

axiosApi.interceptors.request.use(function (request){
    request.headers.Authorization =  `BT ${localStorage.getItem(TOKEN)}`

    return request
}, function (error) {
    return Promise.reject(error)
})

axiosApi.interceptors.response.use(function (response){
    return response
}, function (error) {
    if (error.response) {
        if (error.response.status === 401) {
            if (router.currentRoute.name != 'login'){
                router.push('/login')
            }
        }
    }else{
        store.commit('removerToken')
        router.push('/login')
    }

    return Promise.reject(error)
})
  
export default axiosApi